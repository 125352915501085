import React from 'react'
import Smallnavbar from '../features/navbar/Smallnavbar';
import Book from '../features/booking/Book';
import Footer from '../features/footer/Footer';


export default function Booking() {
  return (
    <div>
      <Smallnavbar/>
      <Book/>
 
      <Footer/>
      
    </div>
  )
}
