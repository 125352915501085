import React , {useState} from "react";
import { NavLink, Link } from "react-router-dom";

export default function Navbar() {
  const [userLogin, setUserLogin] = useState(localStorage.getItem('user') ? "Logout" : "Login");

  const handleLogout = () => {
   if(userLogin=="Logout"){
    localStorage.removeItem('user');
    setUserLogin("Login");
   }

    
  };
  return (
    <div>
      <div className="navbar " style={{ backgroundColor: "#0d416b" }}>
        <div className="navbar-start">
          {/* dropdown of navbar for small screeen */}
          <div className="dropdown">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost lg:hidden"
              style={{ color: "white" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-white rounded-box w-52"
            >
              <div class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/">Home</Link>
              </div>
              <div class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/createform">Create</Link>
              </div>
              <div class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/booking">Book</Link>
              </div>
              <div class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/about">About </Link>
              </div>
              <div class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/gallery">Gallery</Link>
              </div>
              <div class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/ticket">Ticket Checker</Link>
              </div>
            </ul>
          </div>
          {/* logo */}
          <a className="  text-xl " style={{ width: "120px", height: "80px " }}  href="/">
            <img
              src="/image/tudu_logo.png"
              alt="Image Description"
              style={{ width: "3/5", height: "85px" }}
            />
          </a>
        </div>

        {/* navbar and signin */}
        <div className="navbar-end ">
          {/* navbar  */}
          <div className="hidden lg:flex" style={{ color: "white" }}>
            <ul className="menu menu-horizontal px-1 ">
              <li className="hover:bg-sky-700 hover:text-white text-white rounded-lg">
                <NavLink to="/" activeClassName="text-white">
                  Home
                </NavLink>
              </li>
              <li className="hover:bg-sky-700 hover:text-white text-white rounded-lg">
                <NavLink to="/createform" activeClassName="text-white">
                  Create
                </NavLink>
              </li>
              <li className="hover:bg-sky-700 hover:text-white text-white rounded-lg">
                <NavLink to="/booking" activeClassName="text-white">
                  Book
                </NavLink>
              </li>
              <li className="hover:bg-sky-700 hover:text-white text-white rounded-lg">
                <NavLink to="/about" activeClassName="text-white">
                  About
                </NavLink>
              </li>
              <li className="hover:bg-sky-700 hover:text-white text-white rounded-lg">
                <NavLink to="/gallery" activeClassName="text-white">
                  Gallery
                </NavLink>
              </li>
            </ul>
          </div>
          <Link to={userLogin === "Logout" ? "" : "/login"}   >
            <a className="btn ml-3 hover:bg-sky-800 hover:text-white text-white bg-sky-900" onClick={handleLogout} >
           {userLogin}   
            </a>
          </Link>
        </div>
      </div>

      <div
        className="hero h-30"
        style={{ backgroundImage: "url(/image/head_bg.png)" }}
      >
        <div className="hero-overlay bg-opacity-60"></div>
        <div className="hero-content text-center text-neutral-content">
          <div className="">
            <h1
              className="mb-5 m-8 font-bold lg:text-5xl md:text-4xl sm:text-4xl"
              style={{ color: "white" }}
            >
              TuDu Flow Dreams Begin To Glow
            </h1>

            <div class="">
              <Link
                to="/createform"
                className="btn m-2 lg:text-5sl md:text-4sl sm:text-3sl  hover:bg-sky-700 hover:text-white text-lg text-black bg-white"
              >
                Create
              </Link>
              <Link
                to="/booking"
                className="btn m-2 lg:text-5sl md:text-4sl sm:text-3sl  hover:bg-sky-700 hover:text-white text-lg text-black bg-white"
              >
                Book
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}




























