import React from 'react'
import QrReader from '../features/ticket/QrReader'
import Smallnavbar from '../features/navbar/Smallnavbar';
import Footer from '../features/footer/Footer';



export default function Ticket() {
  return (
    <div>

    <Smallnavbar/>
<QrReader/>
<Footer/>
    </div>
  )
}
