import React from 'react';
import {createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import SignupPage from './pages/SignupPage';
import Loginpage from './pages/Loginpage';
import Gallery from './pages/Gallery';
import Create from './pages/Create';
import Aboutus from './pages/Aboutus';
import Booking from './pages/Booking';
import Event from './pages/Event';
import Price from "./pages/Price";
import Qrcode from './pages/Qrcode';
import Ticket from './pages/Ticket';



const router = createBrowserRouter([
  {
    path: '/',
    element: <Home></Home>
  },
  {
    path: '/login',
    element: <Loginpage></Loginpage>
  },
  {
    path: '/signup',
    element: <SignupPage></SignupPage>
  },
  {
    path: '/gallery',
    element: <Gallery></Gallery>
  },
  {
    path: '/createform',
    element: <Create></Create>
  },
  {
    path: '/about',
    element: <Aboutus></Aboutus>
  },
  {
    path: '/booking',
    element: <Booking></Booking>
  },
  {
    path:'/event/:id',
    element:<Event></Event>
  },
  {
    path:'/price/:id',
    element:<Price></Price>
  },
  {
    path:'/qrcode',
    element:<Qrcode></Qrcode>
  },
  {
    path:'/ticket',
    element:<Ticket/>
  }

])

function App() {

  
  return (
  <div>
          <RouterProvider router={router} />
        
  </div>
  );
}

export default App;
