import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-hot-toast';
export default function Login() {
  const navigate = useNavigate(); 
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loading ,setLoading]= useState(false)
  const [errors, setErrors] = useState({}); // State variable to store validation errors

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Clear previous errors
    setErrors({});

    // Perform validation
    const validationErrors = {};
    if (!formData.email.trim()) {
      validationErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      validationErrors.email = 'Email is invalid';
    }
    if (!formData.password.trim()) {
      validationErrors.password = 'Password is required';
    } else if (formData.password.length < 8) {
      validationErrors.password = 'Password should be at least 8 characters';
    }

    // If there are validation errors, set them and return
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_BASE_URL+'api/login', formData);

      console.log('SignIn successful:', JSON.stringify(response.data));

      setLoading(false);
      localStorage.setItem('user', JSON.stringify(response.data));
      navigate('/');
      toast.success('Login successful'); 
    } catch (error) {
      console.error('Error logging in:', error);
      setLoading(false);
      toast.error('Error logging in. Please try again.');
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
    // Clear the corresponding error when input changes
    setErrors(prevErrors => ({
      ...prevErrors,
      [name]: ''
    }));
  };

  return (
    <div className="bg-base-200">
      {loading && (
       <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-gray-800 bg-opacity-50 z-50">
       <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
     </div>
   )}
      <div className="flex flex-col justify-center pt-10 pb-14 sm:px-6 lg:px-8">
        <div className="mt-8 sm:mx-auto bg-base-100 sm:w-full sm:max-w-md">
          <div className="bg-layer-2 py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <h1 className="text-center text-3xl font-semibold text-heading">
              Welcome Back
            </h1>
            <p className="mb-4 mt-2 text-center text-sm text-text">
              Don't have an account?{" "}
              <Link
                to="/signup"
                className="font-semibold text-sky-600 hover:text-primary-accent"
              >
                Sign Up
              </Link>
            </p>
            <form onSubmit={handleSubmit} className="mt-6 flex flex-col space-y-4">
              <div>
                <label htmlFor="email" className="block text-sm font-semibold text-heading">Email</label>
                <input id="email" name="email" type="email" placeholder="Enter your email" value={formData.email} onChange={handleChange} className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-sky-700 focus:outline-none focus:ring-0 sm:text-sm"/>
                {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>} {/* Render email error message */}
              </div>
              <div>
                <label htmlFor="password" className="block text-sm font-semibold text-heading">Password</label>
                <input id="password" name="password" type="password" placeholder="Enter your password" value={formData.password} onChange={handleChange} className="mt-2 block w-full rounded-xl border-2 border-muted-3 bg-transparent px-4 py-2.5 font-semibold text-heading placeholder:text-text/50 focus:border-sky-700 focus:outline-none focus:ring-0 sm:text-sm"/>
                {errors.password && <p className="text-red-500 text-xs mt-1">{errors.password}</p>} {/* Render password error message */}
              </div>
              <div className="flex justify-end">
                <Link
                  to="/forgot-password"
                  className="text-xs text-sky-600 hover:text-primary-accent"
                >
                  Forgot Password?
                </Link>
              </div>
              <button
                type="submit"
                className="inline-flex cursor-pointer items-center justify-center rounded-xl  border-2  border-sky-900 bg-sky-800  px-4 py-2.5 text-sm  font-semibold text-white  shadow-sm  hover:border-sky-900  hover:bg-sky-700 disabled:opacity-30  disabled:hover:text-white"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
