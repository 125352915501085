import React from 'react'
import Navbar from  '../features/navbar/Navbar';
import About from '../features/about/About';
import Footer from '../features/footer/Footer';
import Whytudu from '../features/home/Whytudu';
export default function Aboutus() {
  return (
    <div>
      <Navbar/>
      <About/>
      <Whytudu/>
      <Footer/>
    </div>
  )
}
