import React, { useState } from "react";
import axios from "axios";
import { PhotoIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function Createform() {
  const navigate = useNavigate();
  const [starting_date, setStartDate] = useState("");
  const [ending_date, setEndDate] = useState("");
  const [event_name, setEventName] = useState("");
  const [venue, setVenue] = useState("");
  const [category, setCategory] = useState("");
  const [event_organizer, setOrganizer] = useState("");
  const [event_host, setHost] = useState("");
  const [event_description, setDescription] = useState("");
  const [eventBanner, setCoverPhoto] = useState(null);
  // const [important_links, setImportant_links] = useState([
  //   { name: "", url: "" },
  // ]);
  const [linkName, setLinkName] = useState("");
const [linkUrl, setLinkUrl] = useState("");
  const [bookingOpen, setBookingOpen] = useState("");
  const [bookingStartDate, setBookingStartDate] = useState("");
  const [bookingEndDate, setBookingEndDate] = useState("");
  const [bookingLink, setBookingLink] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("event_name", event_name);
      console.log("event_name:", event_name);

      formData.append("venue", venue);
      console.log("venue:", venue);

      formData.append("start_date", starting_date);
      console.log("start_date:", starting_date);

      formData.append("end_date", ending_date);
      console.log("end_date:", ending_date);

      formData.append("category", category);
      console.log("category:", category);

      formData.append("organizer", event_organizer);
      console.log("organizer:", event_organizer);

      formData.append("host", event_host);
      console.log("host:", event_host);

      formData.append("description", event_description);
      console.log("description:", event_description);

      formData.append("cover_photo", eventBanner);
      console.log("cover_photo:", eventBanner);

      formData.append("booking_open", bookingOpen);
      console.log("booking_open:", bookingOpen);

      formData.append("booking_start_date", bookingStartDate);
      console.log("booking_start_date:", bookingStartDate);

      formData.append("booking_end_date", bookingEndDate);
      console.log("booking_end_date:", bookingEndDate);

      formData.append("booking_link", bookingLink);
      console.log("booking_link:", bookingLink);

      links.forEach((link, index) => {
        formData.append(`important_links[${index}][link_name]`, linkName);
        formData.append(`important_links[${index}][link_url]`, linkUrl);
      
        // Log each link and its name
        console.log(`Link ${index} Name:`, linkName);
        console.log(`Link ${index} URL:`, linkUrl);
      });

      // formData.append("links", JSON.stringify(links));
      // console.log("links:", links);

      const response = await axios.post(
        "http://localhost:8080/api/events",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const message = response.data.message;
      toast.success(message);
      // navigate('/');
      console.log();
      // Handle success or redirect user
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error, show message to the user, etc.
    }
  };

  // date

  const [links, setLinks] = useState([{ name: "", url: "" }]);
  // how many no. of link
  const MAX_LINKS = 6;
  // add links
  const addLink = () => {
    if (links.length < MAX_LINKS) {
      setLinks([...links, { name: "", url: "" }]);
    }
  };
  // delete button of link
  const deleteLink = (index) => {
    const updatedLinks = [...links];
    updatedLinks.splice(index, 1);
    setLinks(updatedLinks);
  };
  //
  const handleLinkNameChange = (index, value) => {
    const updatedLinks = [...links];
    updatedLinks[index].name = value;
    setLinks(updatedLinks);
  };

  const handleLinkUrlChange = (index, value) => {
    const updatedLinks = [...links];
    updatedLinks[index].url = value;
    setLinks(updatedLinks);
  };

  const [eventType, setEventType] = useState("");
  const [ticketCount, setTicketCount] = useState(1);
  const [ticketTypes, setTicketTypes] = useState([{ name: "", price: "" }]);

  const handleEventTypeChange = (event) => {
    setEventType(event.target.value);
    // When changing event type, reset ticket count to 1 and initialize ticket types with one object
    if (event.target.value === "paid") {
      setTicketCount(1);
      setTicketTypes([{ name: "", price: "" }]);
    }
  };

  const handleTicketCountChange = (event) => {
    const count = parseInt(event.target.value);
    setTicketCount(count);
    // When changing ticket count, adjust ticket types accordingly
    setTicketTypes([...new Array(count)].map(() => ({ name: "", price: "" })));
  };

  const handleTicketTypeChange = (index, key, value) => {
    const updatedTypes = [...ticketTypes];
    updatedTypes[index][key] = value;
    setTicketTypes(updatedTypes);
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center pt-20 "
      style={{
        background: "linear-gradient(to bottom, #f0f8ff, #90cdf4, #0d416b)",
      }}
    >
      <form className="w- lg:w-2/3">
        <div className="border-2 rounded-lg  p-6 mb-10 bg-white">
          <div className="space-y-12">
            <div className="border-b border-gray-900/10 pb-12">
              <h2 class="text-lg font-semibold leading-7 text-gray-900">
                Fill the details about event
              </h2>
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                {/* event name */}
                <div className="sm:col-span-4">
                  <label
                    htmlFor="eventName"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Event Name
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                      <input
                        type="text"
                        name="eventName"
                        id="eventName"
                        required
                        autoComplete="eventName"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Event Name"
                        value={event_name}
                        onChange={(e) => setEventName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* venue */}
                <div className="sm:col-span-4">
                  <label
                    htmlFor="venue"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Venue
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                      <input
                        type="text"
                        name="venue"
                        id="venue"
                        required
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Venue"
                        value={venue}
                        onChange={(e) => setVenue(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* Date */}
                <div className="sm:col-span-4">
                  <label
                    htmlFor="startDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Starting Date
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                      <input
                        name="startDate"
                        type="date"
                        id="startdate"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Starting date"
                        value={starting_date}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* Date */}
                <div className="sm:col-span-4">
                  <label
                    htmlFor="endDate"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Ending Date
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                      <input
                        name="endDate"
                        type="date"
                        id="enddate"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Ending date"
                        value={ending_date}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* category */}
                <div className="sm:col-span-3">
                  <label
                    htmlFor="category"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Category
                  </label>
                  <div className="mt-2">
                    <select
                      id="category"
                      name="category"
                      autoComplete="category-name"
                      required
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      <option value="" disabled>
                        Select your category
                      </option>
                      <option>Educational</option>
                      <option>Sports</option>
                      <option>College</option>
                      <option>Office</option>
                      <option>Dance</option>
                      <option>Music</option>
                      <option>Hackathon</option>
                      <option>Gaming</option>
                      <option>Comedy Show</option>
                      <option>Conference</option>
                      <option>Photography</option>
                      <option>Seasonal Event</option>
                      <option>Health and Wellness</option>
                      <option>Fashion Show</option>
                      <option>Startup Pitch</option>
                      <option>Robotics Competition</option>
                      <option>Book Fair</option>
                    </select>
                  </div>
                </div>
                {/* Event Organizer */}
                <div className="sm:col-span-4">
                  <label
                    htmlFor="organizer"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Event Organizer
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                      <input
                        type="text"
                        name="organizer"
                        id="organizer"
                        autoComplete="organizer"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Event Organizer"
                        value={event_organizer}
                        onChange={(e) => setOrganizer(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* Event Host */}
                <div className="sm:col-span-4">
                  <label
                    htmlFor="host"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Event Host
                  </label>
                  <div className="mt-2">
                    <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md">
                      <input
                        type="text"
                        name="host"
                        id="host"
                        autoComplete="host"
                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Event Host"
                        value={event_host}
                        onChange={(e) => setHost(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                {/* about event */}
                <div className="col-span-full">
                  <label
                    htmlFor="about"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Event Description
                  </label>
                  <div className="mt-2">
                    <textarea
                      id="about"
                      name="about"
                      type="text"
                      required
                      placeholder="Event Description: mention the schedule of whole day of the event  "
                      rows={3}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                      value={event_description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
                {/* banner off event */}
              </div>

              {/* Links */}

              <div className="col-span-full">
                <label
                  htmlFor="link"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Important Link
                </label>
                <p className="text-gray-500 text-xs">
                  Add important links and name them
                </p>
                <div>
                  {links.map((link, index) => (
                    <div
                      key={index}
                      className="flex items-center p-2 mb-2 rounded-lg"
                    >
                      <input
                        type="text"
                        placeholder="Link Name"
                        value={link.name}
                        onChange={(e) =>
                          handleLinkNameChange(index, e.target.value)
                        }
                        className="block border-black mr-4 flex-1 rounded-lg bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                      <input
                        type="text"
                        placeholder="Link URL"
                        value={link.url}
                        onChange={(e) =>
                          handleLinkUrlChange(index, e.target.value)
                        }
                        className="block border-black flex-1 rounded-lg bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                      />
                      {index !== 0 && (
                        <button
                          type="button"
                          onClick={() => deleteLink(index)}
                          className="text-red-600"
                        >
                          &#x2716;
                        </button>
                      )}
                    </div>
                  ))}
                  {links.length < MAX_LINKS && (
                    <button
                      type="button"
                      onClick={addLink}
                      className="bg-blue-600 text-white rounded-lg px-3 py-1.5 text-sm font-semibold hover:bg-blue-500"
                    >
                      + Add Link
                    </button>
                  )}
                </div>
              </div>

              {/* ticket  */}
              <div className="col-span-full">
                <label
                  htmlFor="event-type"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Event Pricing
                </label>
                <div className="mt-2">
                  <select
                    id="event-type"
                    name="event-type"
                    autoComplete="event-type"
                    required
                    className="block w-full border-black rounded-lg py-1.5  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    onChange={handleEventTypeChange}
                    value={eventType}
                  >
                    <option value="" disabled selected>
                      Select event pricing
                    </option>
                    <option value="free">Free</option>
                    <option value="paid">Paid</option>
                  </select>
                  {eventType === "free" ? (
                    <p className="text-black">This event is free!</p>
                  ) : eventType === "paid" ? (
                    <div>
                      <label className="block text-sm font-medium leading-6 text-gray-900 mt-2">
                        Number of Tickets
                      </label>
                      <p className="text-gray-500 text-xs">
                        Add important links and name them
                      </p>
                      <div className="flex mt-2 text-black">
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((count) => (
                          <label
                            key={count}
                            className="inline-flex items-center mr-4"
                          >
                            <input
                              type="radio"
                              name="ticket-count"
                              value={count}
                              checked={ticketCount === count}
                              onChange={handleTicketCountChange}
                              className="form-radio h-4 w-4 text-blue-600 border-blue-400 focus:ring-blue-500"
                            />
                            <span className="ml-2">{count}</span>
                          </label>
                        ))}
                      </div>
                      {ticketTypes.map((ticket, index) => (
                        <div key={index} className="mt-2 flex flex-wrap">
                          <div className="w-1/2 pr-2">
                            <label
                              htmlFor={`ticket-name-${index}`}
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Ticket {index + 1} Name
                            </label>
                            <input
                              id={`ticket-name-${index}`}
                              name={`ticket-name-${index}`}
                              type="text"
                              value={ticket.name}
                              onChange={(event) =>
                                handleTicketTypeChange(
                                  index,
                                  "name",
                                  event.target.value
                                )
                              }
                              className="block w-full border-black rounded-lg py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              placeholder={`Ticket ${index + 1} Name`}
                            />
                          </div>
                          <div className="w-1/2 pl-2">
                            <label
                              htmlFor={`ticket-price-${index}`}
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              Ticket {index + 1} Price (in Rs)
                            </label>
                            <input
                              id={`ticket-price-${index}`}
                              name={`ticket-price-${index}`}
                              type="number"
                              min="0"
                              step="1"
                              value={ticket.price}
                              onChange={(event) =>
                                handleTicketTypeChange(
                                  index,
                                  "price",
                                  event.target.value
                                )
                              }
                              className="block w-full border-black rounded-lg py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              placeholder={`Ticket ${index + 1} Price`}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
              </div>

              {/* banner off event */}
              <div className="col-span-full">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Event Banner
                </label>
                <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div className="text-center">
                    <PhotoIcon
                      className="mx-auto h-12 w-12 text-gray-300"
                      aria-hidden="true"
                    />
                    <div className="mt-4 flex text-sm leading-6 text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-600 focus-within:ring-offset-2 hover:text-blue-500"
                      >
                        <span>Upload a file</span>
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          required
                          className="sr-only"
                          value={eventBanner}
                          onChange={(e) => setCoverPhoto(e.target.files[0])}
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs leading-5 text-gray-600">
                      PNG, JPG, GIF up to 10MB
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* cancel or sav button */}
          <div class="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="button"
              class="text-sm font-semibold leading-6 text-gray-900"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="rounded-lg bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={handleSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
