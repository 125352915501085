import { useEffect, useRef, useState } from "react";
import QrScanner from "qr-scanner";

const QrReader = () => {
  const scanner = useRef(null);
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const [scannedResult, setScannedResult] = useState("");

  const onScanSuccess = (result) => {
    console.log(result);
    setScannedResult(result?.data);
  };

  const onScanFail = (err) => {
    console.log(err);
  };

  const cancelScan = () => {
    setScannedResult(""); 
  };

  useEffect(() => {
    if (videoEl.current && !scanner.current) {
      scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
        onDecodeError: onScanFail,
        preferredCamera: "environment",
        highlightScanRegion: true,
        highlightCodeOutline: true,
        overlay: qrBoxEl.current || undefined,
      });

      scanner.current
        .start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    return () => {
      if (videoEl.current) {
        scanner.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn) {
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
    }
  }, [qrOn]);

  return (
    <div className="qr-reader w-screen h-screen mx-auto relative">
      <video ref={videoEl} className="w-full h-full object-cover"></video>
      <div ref={qrBoxEl} className="qr-box w-full">
        <img
          src="/image/qr-frame.svg"
          alt="Qr Frame"
          width={256}
          height={256}
          className="qr-frame absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
        />
      </div>

      {scannedResult && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 99999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            padding: "10px",
          }}
        >
          <p className ="bg-sky-500 p-4" style={{ color: "white" }}>Scanned Result: {scannedResult}</p>
          <button className="bg-white text-sky-500 p-6 border-4" onClick={cancelScan}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default QrReader;


// import { useEffect, useRef, useState } from "react";
// import QrScanner from "qr-scanner";
// import "./QrStyles.css";

// const QrReader = () => {
//   // QR States
//   const scanner = useRef(null);
//   const videoEl = useRef(null);
//   const qrBoxEl = useRef(null);
//   const [qrOn, setQrOn] = useState(true);
//   const [scannedResult, setScannedResult] = useState("");

//   // Success
//   const onScanSuccess = (result) => {
//     console.log(result);
//     setScannedResult(result?.data);
//   };

//   // Fail
//   const onScanFail = (err) => {
//     console.log(err);
//   };

//   useEffect(() => {
//     if (videoEl.current && !scanner.current) {
//       scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
//         onDecodeError: onScanFail,
//         preferredCamera: "environment",
//         highlightScanRegion: true,
//         highlightCodeOutline: true,
//         overlay: qrBoxEl.current || undefined,
//       });

//       scanner.current
//         .start()
//         .then(() => setQrOn(true))
//         .catch((err) => {
//           if (err) setQrOn(false);
//         });
//     }

//     return () => {
//       if (videoEl.current) {
//         scanner.current.stop();
//       }
//     };
//   }, []);

//   useEffect(() => {
//     if (!qrOn) {
//       alert(
//         "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
//       );
//     }
//   }, [qrOn]);

//   return (
//     <div className="qr-reader">
//       <video ref={videoEl}></video>
//       <div ref={qrBoxEl} className="qr-box">
//         <img
//           src="/image/qr-frame.svg"
//           alt="Qr Frame"
//           width={256}
//           height={256}
//           className="qr-frame"
//         />
//       </div>

//       {scannedResult && (
//         <p
//           style={{
//             position: "absolute",
//             top: 0,
//             left: 0,
//             zIndex: 99999,
//             color: "white",
//           }}
//         >
//           Scanned Result: {scannedResult}
//         </p>
//       )}
//     </div>
//   );
// };

// export default QrReader;
