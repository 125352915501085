import React from 'react';
import Marquee from "react-fast-marquee";

export default function Trendytudu() {
  return (
 
<div className=" pt-4 bg-gradient-to-b from-blue-900 via-blue-500 to-blue-300 h-100">
      <div className='  '>
        <h2 className="text-2xl font-bold mb-2  text-center text-neutral-50">Trendy TuDu</h2>
        <Marquee  >
          <div className='w-56 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal1.png" alt="" className='rounded-lg'/></div>
          <div className='w-56 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal2.png" alt="" className='rounded-lg'/></div>
          <div className='w-56 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal3.png" alt="" className='rounded-lg'/></div>
          <div className='w-56 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal4.png" alt="" className='rounded-lg'/></div>
          <div className='w-56 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal5.png" alt="" className='rounded-lg'/></div>
          <div className='w-56 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal6.png" alt="" className='rounded-lg'/></div>
          <div className='w-56 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal7.png" alt="" className='rounded-lg'/></div>
        </Marquee>
      </div>
    </div>
  )
}
