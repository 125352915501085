import { items } from "./items";
import React, { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import { Link } from "react-router-dom";

export default function Book() {
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filteredItems, setFilteredItems] = useState(items);

  const formatDateRange = (startDate, endDate) => {
    if (
      startDate &&
      endDate &&
      startDate.trim() !== "" &&
      endDate.trim() !== ""
    ) {
      const startDay = startDate.split(" ")[0];
      const month = startDate.split(" ")[1];
      const year = startDate.split(" ")[2];
      const endDay = endDate.split(" ")[0];
      return `${month} ${startDay}-${endDay}, ${year}`;
    } else if (startDate && startDate.trim() !== "") {
      const startDay = startDate.split(" ")[0];
      const month = startDate.split(" ")[1];
      const year = startDate.split(" ")[2];
      return `${month} ${startDay}, ${year}`;
    } else {
      return "Coming Soon";
    }
  };

  let filters = [
    "Education",
    "College",
    "Sports",
    "Office",
    "Dance",
    "Music",
    "Hackathon",
    "Gaming",
    "Comedy Show",
    "Conference",
    "Photography",
    "Seasonal Event",
    "Health and Wellness",
    "Fashion Show",
    "Startup Pitch",
    "Robotics Competition",
    "Book Fair",
  ];

  const handleFilterButtonClick = (selectedCategory) => {
    if (selectedFilters.includes(selectedCategory)) {
      let filters = selectedFilters.filter((el) => el !== selectedCategory);
      setSelectedFilters(filters);
    } else {
      setSelectedFilters([...selectedFilters, selectedCategory]);
    }
  };

  useEffect(() => {
    filterItems();
  }, [selectedFilters]);

  const filterItems = () => {
    const currentDate = new Date();
    const filteredItems = items.filter((item) => {
      const endDate = item.endDate ? new Date(item.endDate) : null;
      const startDate = item.startDate ? new Date(item.startDate) : null;
      if (endDate) {
        return endDate >= currentDate;
      }
      if (startDate) {
        return startDate >= currentDate;
      }
      return true;
    });
    if (selectedFilters.length > 0) {
      const filteredByCategory = filteredItems.filter((item) =>
        selectedFilters.includes(item.category)
      );
      setFilteredItems(filteredByCategory);
    } else {
      setFilteredItems(filteredItems);
    }
  };

  const sortedItems = filteredItems.slice().sort((a, b) => {
    const startDateA = new Date(a.startDate);
    const startDateB = new Date(b.startDate);
    return startDateA - startDateB;
  });

  return (
    <div>
      <div className="bg-gradient-to-b from-blue-300 via-blue-200 to-blue-400 pt-8 min-h-screen flex flex-col items-center">
        <div className="search-bar-container text-black w-64 md:w-80 lg:w-96">
          <Autocomplete
            multiple
            id="search-bar"
            options={filters}
            value={selectedFilters}
            onChange={(_, newValue) => setSelectedFilters(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Search Categories"
                placeholder="Select Categories"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={index}
                  label={option}
                  onDelete={() => handleFilterButtonClick(option)}
                  {...getTagProps({ index })}
                  className="mx-1 my-1 text-black"
                />
              ))
            }
          />
        </div>

        <div className="flex flex-wrap justify-center items-center mt-4 text-black w-full">
          {sortedItems
            .filter((item) => {
              const startDate = new Date(item.startDate);
              const currentDate = new Date();
              return startDate >= currentDate;
            })
            .map((item, idx) => (
              <div
                key={`items-${idx}`}
                className="p-4  sm:w-2/5 md:w-1/3 lg:w-1/4 xl:w-1/5 border border-solid border-black rounded flex flex-col justify-between m-2"
              >
                <img
                  src={item.image}
                  alt={`Event ${idx + 1}`}
                  className="mb-2 rounded-md object-fill h-48 w-88 "
                />

                <p className="text-black font-semibold text-lg overflow-hidden whitespace-nowrap max-w-xs">
                  {item.name}
                </p>

                <p className="flex items-center  text-black text-xs lg:text-sm text-wite">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                    className="h-3 w-3 m-0.5 text-black-500"
                  >
                    <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                  </svg>
                  {item.venue}
                </p>
                <p className="flex items-center text-black text-xs lg:text-sm text-wite">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    className="h-3 w-3 m-0.5 text-black-500"
                  >
                    <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
                  </svg>
                  <p>{formatDateRange(item.startDate, item.endDate)}</p>
                </p>

                <div className="flex justify-center mt-2">
                  <Link
                    to={`/event/${item.id}`}
                    className="bg-blue-800 text-white hover:bg-blue-900 px-2 py-2 rounded-md text-lg font-semibold m-1"
                  >
                    Book Now
                  </Link>
                </div>

                {/* Optional: Category for sorting */}
                <p className="hidden">{item.category}</p>
                <p className="hidden">{item.id}</p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}