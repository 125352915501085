import React from "react";
import { NavLink, Link } from "react-router-dom";

export default function Navbar() {
  return (
    <div>
      <div className="navbar " style={{ backgroundColor: "#0D416b" }}>
        <div className="navbar-start">
          {/* dropdown of navbar for small screeen */}
          <div className="dropdown">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost lg:hidden"
              style={{ color: "white" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h8m-8 6h16"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-white rounded-box w-52"
            >
              <li class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/">Home</Link>
              </li>
              <li class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/createform">Create</Link>
              </li>
              <li class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/booking">Book</Link>
              </li>
              <li class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/about">About </Link>
              </li>
              <li class=" hover:bg-sky-700 hover:text-white text-black rounded-lg">
                <Link to="/gallery">Gallery</Link>
              </li>
            </ul>
          </div>
          {/* logo */}
          <a className="  text-xl " style={{ width: "120px", height: "80px " }}  href="/">
            <img
              src="/image/tudu_logo.png"
              alt="Image Description"
              style={{ width: "3/5", height: "85px" }}
            />
          </a>
        </div>

        {/* navbar and signin */}
        <div className="navbar-end ">
          {/* navbar  */}
          <div className="hidden lg:flex" style={{ color: "white" }}>
            <ul className="menu menu-horizontal px-1 ">
              <li className="hover:bg-sky-700 hover:text-white text-white rounded-lg">
                <NavLink to="/" activeClassName="text-white">
                  Home
                </NavLink>
              </li>
              <li className="hover:bg-sky-700 hover:text-white text-white rounded-lg">
                <NavLink to="/createform" activeClassName="text-white">
                  Create
                </NavLink>
              </li>
              <li className="hover:bg-sky-700 hover:text-white text-white rounded-lg">
                <NavLink to="/booking" activeClassName="text-white">
                  Book
                </NavLink>
              </li>
              <li className="hover:bg-sky-700 hover:text-white text-white rounded-lg">
                <NavLink to="/about" activeClassName="text-white">
                  About
                </NavLink>
              </li>
              <li className="hover:bg-sky-700 hover:text-white text-white rounded-lg">
                <NavLink to="/gallery" activeClassName="text-white">
                  Gallery
                </NavLink>
              </li>
            </ul>
          </div>
          <Link to="/login">
            <a className="btn ml-3 hover:bg-sky-800 hover:text-white text-white bg-sky-900">
              Login
            </a>
          </Link>
        </div>
      </div>
    </div>
  );
}
