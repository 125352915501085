export const items = [
  {
    id: "5",
    name: "Rama Navami",
    href: "",
    category: "Office",
    venue: "DHE Office",
    startDate: "9 April 2024",
    endDate: "17 April 2024",
    image: "/image/event1.png",
    organizer: "Your Organizer",
    host: "Your Host",
    description: "Coming Soon",
    tickets: [
      // { name: "Research Scholars and Students (REGULAR)", price: 1100 },
    ],
    links: [
      // { name: "Paper Submission", url: "https://sk24.rase.co.in/paper" },
    ],
    
  },
  {
    id: "1",
    name: "NATIONAL CONFERENCE",
    href: "https://sk24.rase.co.in/",
    category: "Education",
    venue: "NIT Srinagar",
    startDate: "29 June 2024",
    endDate: "30 June 2024",
    image: "/image/nitsirinagar.png",
    organizer: "Your Organizer",
    host: "Your Host",
    description: (
      <div>
        <p className="mt-4 text-justify text-black whitespace-pre-line">
          NIT Srinagar, established in 1960 and later designated as a National
          Institute of Technology in 2003, stands as a prominent educational
          institution. Situated alongside Dal Lake, it offers a spectrum of
          programs including BTech, MTech, MPhil, and Ph.D. It boasts
          comprehensive facilities such as hostels, NCC, NSS, and a well-stocked
          library. Notably, the Department of Holistic Education emphasizes
          education grounded in Indian values. Introducing "Shiksha Kumbh" as an
          extension of "Shiksha Mahakumbh," the initiative aims to widen
          educational horizons. Moreover, the conference "Role of Academic
          driven Startups in Economy" seeks to delve into the impact and
          assistance provided by educational institutes to startups. It
          highlights the importance of startups, collaboration fostering, skill
          development, and rural outreach among its key objectives.
          <br />
          <br />
          For Paper Registration:
          <br />
          Selected papers will be published as conference proceedings in Scopus
          indexed journals. 1st, 2nd and 3rd prize of ₹21K, ₹11K & ₹5K will be
          given. 5 consolation prizes of ₹3K each will be provided
        </p>
        <br/>
        <p>BOOK OR REGISTER THROUGH THE LINKS GIVEN BELOW</p>
      </div>
    ),
    tickets: [
      { name: "Research Scholars and Students (REGULAR)", price: 1100 },
      { name: "Research Scholars and Students (LATE)", price: 1500 },
      { name: "Academics, R&D and Institutions(REGULAR)", price: 2100 },
      { name: "Academics, R&D and Institutions (LATE)", price: 2500 },
      { name: " Industry (REGULAR)", price: 3100 },
      { name: "Industry (LATE)", price: 3500 },
    ],
    links: [
      { name: "Paper Submission", url: "https://sk24.rase.co.in/paper" },
      {name: "Volunteer Registration Form", url: "https://sk24.rase.co.in/registration/volunteer",},
      {name: "NGO Registration Form", url: "https://sk24.rase.co.in/registration/ngo",},
      {name: "Participant Registration", url: "https://sk24.rase.co.in/registration/participant",},
      { name: "Book Your Accommodation", url: "https://ac.rase.co.in/" },
    ],
  },

  {
    id: "2",
    name: "Shiksha Kumbh ",
    href: "",
    category: "Education",
    venue: "NIT Delhi",
    startDate: "1 August 2024",
    endDate: "",
    image: "/image/nitdelhi.png",
    organizer: "Your Organizer",
    host: "Your Host",
    description: "Coming Soon",
    tickets: [
      // { name: "Research Scholars and Students (REGULAR)", price: 1100 },
    ],
    links: [
      // { name: "Paper Submission", url: "https://sk24.rase.co.in/paper" },
    ],
  },
  {
    id: "3",
    name: "Shiksha Kumbh ",
    href: "",
    category: "Education",
    venue: "NIT Hamirpur",
    startDate: "1 September 2024",
    endDate: "",
    image: "/image/nithamirpur.png",
    organizer: "Your Organizer",
    host: "Your Host",
    description: "Coming Soon",
    tickets: [
      // { name: "Research Scholars and Students (REGULAR)", price: 1100 },
    ],
    links: [
      // { name: "Paper Submission", url: "https://sk24.rase.co.in/paper" },
    ],
  },
  {
    id: "4",
    name: "Shiksha Mahakumbh ",
    href: "",
    category: "Education",
    venue: "IIT Ropar",
    startDate: "4 October 2024",
    endDate: "6 October 2024",
    image: "/image/iitropar.png",
    organizer: "Your Organizer",
    host: "Your Host",
    description: "Coming Soon",
    tickets: [
      // { name: "Research Scholars and Students (REGULAR)", price: 1100 },
    ],
    links: [
      // { name: "Paper Submission", url: "https://sk24.rase.co.in/paper" },
    ],
  },

  {
    id: "6",
    name: "Chandigarh Comedy Festival",
    href: "https://in.bookmyshow.com/events/chandigarh-comedy-festival/ET00337834",
    category: "Comedy",
    venue: "To Be Announced: Chandigarh",
    startDate: "23 November 2024",
    endDate: "24 November 2024",
    image: "/image/event3.png",
    organizer: "Your Organizer",
    host: "Your Host",
    description: (
      <div className="text-black">
        <ul className="list-disc text-black ml-4">
          <li>
            India's biggest and Chandigarh's very own comedy festival is
            back.&nbsp;
          </li>
          <li>
            This is a 2 Day Event, dates this year are 23rd &amp; 24th November
            2024
          </li>
          <li>
            The duration of the event will be 5 Hours on both Days, On both days
            the event will start at 5 PM and will end by 10 PM.
          </li>
          <li>The venue for the event is Kalagram,&nbsp;Chandigarh.</li>
          <li>
            If you register on this pre-sale registration link now, you will get
            a link to discounted tickets 1 week before the start of the actual
            sales.
          </li>
          <li>
            Pre-sale link with heavily discounted tickets will be sent to you
            only if you register.
          </li>
          <li>
            Regular Priced tickets/Festival tickets sale will start on 01st
            October 2024
          </li>
        </ul>

      </div>
    ),
    tickets: [
      // { name: "Research Scholars and Students (REGULAR)", price: 1100 },
    ],
    links: [
      // { name: "Paper Submission", url: "https://sk24.rase.co.in/paper" },
    ],
  },
  {
    id: "7",
    name: "Gujarat Titans vs Delhi Capitals",
    href: "  ",
    category: "Sports",
    venue: "Arun Jaitley Stadium, Delhi",
    startDate: "24 April 2024",
    endDate: "",
    image: "/image/event2.png",
    organizer: "Your Organizer",
    host: "Your Host",
    description: "Coming Soon",
    tickets: [
      // { name: "Research Scholars and Students (REGULAR)", price: 1100 },
    ],
    links: [
      // { name: "Paper Submission", url: "https://sk24.rase.co.in/paper" },
    ],
  },
  {
    id: "8",
    name: "Pacific Dance Delhi Dance",
    href: "https://www.townscript.com/e/pacific-dance-delhi-dance-season-iii-043402",
    category: "Dance",
    venue: "Pitampura, New Delhi",
    startDate: "28 March 2024",
    endDate: "10 May 2024",
    image: "/image/event4.png",
    organizer: "Your Organizer",
    host: "Your Host",
    description: (
      <div>
        <p>
          A dance competition where participant from a variety of dance genre
          enter open auditions held in Pacific Mall, NSP, Pitampura and showcase
          their unique dance move and talents. The participants are allowed to
          move forward and put through audition round on 11th May. At the end of
          audition, finalist are chosen who move on to compete in the
          competition's main phase on 18th May, and will perform solo/ group
          dance numbers in a variety of styles.
        </p>
      </div>
    ),
    tickets: [
      // { name: "Research Scholars and Students (REGULAR)", price: 1100 },
    ],
    links: [
      // { name: "Paper Submission", url: "https://sk24.rase.co.in/paper" },
    ],
  },
];