import React from 'react';
import Smallnavbar from '../features/navbar/Smallnavbar';
import Createform from '../features/create/Createform';
import Footer from '../features/footer/Footer';
export default function Create() {
  return (
    <div>
      <Smallnavbar/>
      <Createform/>
      <Footer/>
    </div>
  )
}
