import React from 'react';
import Navbar from '../features/navbar/Smallnavbar';
import EventDetails from '../features/booking/EventDetails';
import Footer from '../features/footer/Footer';

export default function Event() {
  return (
    <div>
      <Navbar />
      <EventDetails />
      <Footer />
    </div>
  );
}
