import React from 'react';
import Marquee from "react-fast-marquee";



export default function Gallerycontent() {
  const headingStyle = {
    fontFamily: "'Londrina Shadow', 'Poppins', 'Roboto', sans-serif",
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#fff', 
  };

  return (
    <div>
            <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Londrina+Shadow&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto&display=swap');
        `}
      </style>
      <div className=" pt-2 bg-gradient-to-b from-blue-900 via-blue-500 to-blue-300 h-100">
      <article className="text-wrap flex flex-col items-center justify-center my-6">
  <h1 className="text-balance font-bold text-2xl md:text-5xl lg:text-7xl text-white">Gallery</h1>
</article>



       <div className='  '>
        <Marquee  speed={10} direction="left" >
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal4.png" alt="gal4" loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal5.png" alt="gal5" loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal6.png" alt="gal6" loading= "lazy" className='rounded-lg r' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal3.png" alt="gal3" loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal1.png" alt="gal1" loading= "lazy" className='rounded-lg' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal2.png" alt="gal2" loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
        </Marquee>
       </div>

       <div className='  '>
        <Marquee speed={10} direction="right">
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal13.png" alt="gal13"  loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal14.png" alt="gal14"  loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal15.png" alt="gal15"  loading= "lazy" className='rounded-lg r' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal16.png" alt="gal16"  loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal17.png" alt="gal17"  loading= "lazy" className='rounded-lg' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal18.png" alt="gal18"  loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
        </Marquee>
       </div>

       <div className='  '>
       <Marquee speed={10} direction="left">
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal7.png" alt="gal7"  loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal8.png" alt="gal8"  loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal9.png" alt="gal9"  loading= "lazy" className='rounded-lg r' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal10.png" alt="gal10" loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal11.png" alt="gal11" loading= "lazy" className='rounded-lg' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal12.png" alt="gal12" loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
       </Marquee>
       </div> 
       
       {/* <div className='  '>
       <Marquee speed={10} direction="right">
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal7.png" alt=""  loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal8.png" alt=""  loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal9.png" alt=""  loading= "lazy" className='rounded-lg r' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal10.png" alt="" loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal11.png" alt="" loading= "lazy" className='rounded-lg' style={{ width: '100%', height: '100%' }}/></div>
          <div className='w-60 h-50 m-3 transform transition-transform hover:scale-110'><img src="./image/gal12.png" alt="" loading= "lazy" className='rounded-lg ' style={{ width: '100%', height: '100%' }}/></div>
        </Marquee>
       </div> */}
       

      </div>
    </div>

    
  )
}