import React, { useState } from "react";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";

export default function QRpage() {
  const [formData, setFormData] = useState({
    your_name: "",
    email: "",
    phone: ""
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
  };

  const downloadQRCode = () => {
    const svg = document.getElementById("qrcode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    canvas.width = svg.width.baseVal.value;
    canvas.height = svg.height.baseVal.value;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      const dataUrl = canvas.toDataURL("image/png");

      //  QR code as an image
      const a = document.createElement("a");
      a.href = dataUrl;
      a.download = "qr-code.png";
      a.click();
    };
    img.src =` data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <div>
      <div
        className="min-h-screen flex items-center justify-center pt-20"
        style={{
          background:
            "linear-gradient(to bottom, #f0f8ff, #90cdf4, #0d416b)"
        }}
      >
        <div className="sm:flex">
          <form className="w-full mr-8 sm:mb-0 " onSubmit={handleSubmit}>
            <div className="border-2 rounded-lg p-6 mb-10 bg-white">
              <div className="space-y-12">
                <div className="border-b border-gray-900/10 pb-4">
                  <h2 className="text-lg font-semibold leading-7 text-gray-900">
                    Fill your details for Booking
                  </h2>
                  <div className="mt-6">
                    <div className="flex flex-col mb-4">
                      <label
                        htmlFor="your_name"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Your Name
                      </label>
                      <input
                        type="text"
                        name="your_name"
                        id="your_name"
                        required
                        autoComplete="your_name"
                        className="block border-black rounded-lg bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Your Name"
                        value={formData.your_name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex flex-col mb-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        required
                        autoComplete="email"
                        className="block border-black rounded-lg bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex flex-col mb-4">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        id="phone"
                        required
                        autoComplete="tel"
                        pattern="[0-9]{10}"
                        className="block border-black rounded-lg bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        placeholder="Phone Number"
                        value={formData.phone}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="flex justify-center">
                      <button
                        type="submit"
                        className="bg-blue-800 text-white hover:bg-blue-900 px-4 py-2 rounded-md text-lg font-semibold"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {/* QR code */}
          {submitted && (
            <div className=" mx-auto lg:mr-0 mb-10 ">
              <div className="border border-gray-900/10 p-6 rounded-lg bg-white">
                <h2 className="text-lg font-semibold leading-7 text-gray-900">
                  Your QR Code
                </h2>
                {/* QR code */}
                <QRCode
                  id="qrcode" // id to the QR 
                  value={JSON.stringify(formData)} //  string to generate QR
                  renderAs="svg" //  QR as SVG
                  size={256} // size 
                />
                {/* download button*/}
                <div className="flex justify-center mt-4 ">
                  <button
                    onClick={downloadQRCode}
                    className="bg-blue-800 text-white hover:bg-blue-900 px-4 py-2 rounded-md text-lg font-semibold"
                  >
                    Download QR Code
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}