import React from 'react';
import Navbar from  '../features/navbar/Navbar';
import Carousel from '../features/home/Carousel';
import Footer from '../features/footer/Footer';
import Whytudu from '../features/home/Whytudu';
import Trendytudu from '../features/home/Trendytudu';


export default function Home() {
  return (
    <div>
      {/* Navbar Component */}
      <Navbar />
      {/* Carousel Component */}
      <Carousel />
      {/* Why TUDU */}
      <Whytudu/>
      {/*  trend TUDU */}
      <Trendytudu/>
      {/* Footer Component*/}
      <Footer/>
    </div>
  );
}
