import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { items } from "./items";
import { Link } from "react-router-dom";

const PriceGrid = () => {
  const { id } = useParams();
  const [counts, setCounts] = useState(items.map(() => 0));

  const price = items.find((item) => item.id === id);

  if (!price) {
    return <div>No event found</div>;
  }

  const handleAddToCart = (ticket, index) => {
    console.log(`Added ${ticket.name} to cart with count: ${counts[index]}`);
  };

  return (
    <div>
      {/* Optional: Category for sorting */}
      <p className="hidden">{price.category}</p>
      <p className="hidden">{price.id}</p>

      <div
        style={{
          background: "linear-gradient(to bottom, #f0f8ff, #90cdf4, #3a7bb0)",
        }}
      >
        <div className="container mx-auto p-4 flex justify-center items-center">
          <div
            key={`item-${price.id}`}
            className="max-w-full p-4 border border-black "
          >
            <div className="text-blue-900 font-extrabold text-lg uppercase m-4  max-w-md text-center">
              <p className="text-center">{price.name}</p>
              <p className="text-center">
                &#9679; {price.venue}
                &#9679; {price.startDate} - {price.endDate}
              </p>
            </div>
            {/* Render ticket details */}
            <div class="grid grid-cols-1 gap-4 bg-gray-100 p-4 rounded-lg">
              {price.tickets.map((ticket, index) => (
                <div class="flex justify-between items-center" key={index}>
                  <div>
                    <p class="text-xl font-semibold text-black">
                      {ticket.name}
                    </p>
                    <p class="text-sm text-black">{ticket.description}</p>
                    <p class="text-lg font-medium text-black">
                      {" "}
                      &#8377;{ticket.price}
                    </p>
                  </div>
                  <div className="flex items-center">
                    <button
                      onClick={() => {
                        if (counts[index] > 1) {
                          const newCounts = [...counts];
                          newCounts[index] -= 1;
                          setCounts(newCounts);
                        }
                      }}
                      className="bg-blue-500 text-white px-3 py-1 rounded-l"
                    >
                      -
                    </button>
                    <p className="bg-blue-500 text-white px-3 py-1">{counts[index]}</p>
                    <button
                      onClick={() => {
                        if (counts[index] < 10) {
                          const newCounts = [...counts];
                          newCounts[index] += 1;
                          setCounts(newCounts);
                        }
                      }}
                      className="bg-blue-500 text-white px-3 py-1 rounded-r"
                    >
                      +
                    </button>
                  </div>
                </div>
              ))}
            </div>


            {/* book now */}
            <div className="flex justify-center mt-2">
              <Link
                to={`/qrcode`}
                className="bg-blue-800 text-white hover:bg-blue-900 px-2 py-2 rounded-md text-lg font-semibold m-1"
              >
                Book Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceGrid;