import React from 'react'
import Smallnavbar from '../features/navbar/Smallnavbar';
import Footer from '../features/footer/Footer'
import QRpage from '../features/booking/QRpage';

export default function Qrcode() {
  return (
    <div>
      <Smallnavbar/>
      <QRpage/>
      <Footer/>
    </div>
  )
}