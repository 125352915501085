import React, { useState, useEffect } from 'react';


export default function Carousel() {
  const [countdown, setCountdown] = useState({
    days: 15,
    hours: 10,
    minutes: 24,
    seconds: 57,
  });

  useEffect(() => {
    // setInterval decrese the time in every second (function ha jo time ko kam kar raha ha )
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        const { days, hours, minutes, seconds } = prevCountdown;

        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
          // Countdown has reached zero, you can handle this as needed
          clearInterval(interval);
          return prevCountdown;
        }

        let newDays = days;
        let newHours = hours;
        let newMinutes = minutes;
        let newSeconds = seconds - 1;

        if (newSeconds < 0) {
          newSeconds = 59;
          newMinutes -= 1;
        }

        if (newMinutes < 0) {
          newMinutes = 59;
          newHours -= 1;
        }

        if (newHours < 0) {
          newHours = 23;
          newDays -= 1;
        }

        return {
          days: newDays,
          hours: newHours,
          minutes: newMinutes,
          seconds: newSeconds,
        };
      });
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div>
      <div className="bg-cover bg-center bg-gradient-to-b from-blue-300 via-blue-500 to-blue-900">
        <div className="grid grid-flow-col gap-5 text-center auto-cols-max py-4  justify-center">
          <div className="flex flex-col p-2 bg-neutral rounded-box  text-white">
            <span className="countdown font-mono text-5xl ">
              <span style={{ "--value": countdown.days }}></span>
            </span>
            days
          </div>
          <div className="flex flex-col p-2 bg-neutral rounded-box  text-white">
            <span className="countdown font-mono text-5xl ">
              <span style={{ "--value": countdown.hours }}></span>
            </span>
            hours
          </div>
          <div className="flex flex-col p-2 bg-neutral rounded-box  text-white">
            <span className="countdown font-mono text-5xl ">
              <span style={{ "--value": countdown.minutes }}></span>
            </span>
            min
          </div>
          <div className="flex flex-col p-2 bg-neutral rounded-box  text-white">
            <span className="countdown font-mono text-5xl ">
              <span style={{ "--value": countdown.seconds }}></span>
            </span>
            sec
          </div>
        </div>

        <div className="w-full flex justify-center  pb-8">
          <div className="carousel w-4/6  rounded-lg ">
            <div id="slide1" className="carousel-item relative w-full">
              <img src="./image/slide1.png" className="w-full" />
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2 ">
                <a href="#slide3" className="btn btn-circle text-base sm:text-base bg-inherit ">❮</a>
                <a href="#slide2" className="btn btn-circle text-sm sm:text-base bg-inherit  ">❯</a>
              </div>
            </div>
            <div id="slide2" className="carousel-item relative w-full">
              <img src="./image/slide2.png" className="w-full" />
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <a href="#slide1" className="btn btn-circle text-sm sm:text-base bg-inherit  ">❮</a>
                <a href="#slide3" className="btn btn-circle text-sm sm:text-base bg-inherit  ">❯</a>
              </div>
            </div>

            <div id="slide3" className="carousel-item relative w-full">
              <img src="./image/slide3.png" className="w-full" />
              <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                <a href="#slide2" className="btn btn-circle text-sm sm:text-base bg-inherit  ">❮</a>
                <a href="#slide1" className="btn btn-circle text-sm sm:text-base bg-inherit  ">❯</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
