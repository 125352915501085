import React from 'react';
import Login from '../features/auth/Login';

export default function Loginpage() {
  return (
    <div>
      <Login/>
     
    </div>
  )
}
