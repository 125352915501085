import React from 'react'
import Navbar from '../features/navbar/Navbar'
import PriceGrid from '../features/booking/PriceGrid'
import Footer from '../features/footer/Footer'

export default function Price() {
  return (
    <div>
      <Navbar/>
      <PriceGrid/>
      <Footer/>
    </div>
  )
}