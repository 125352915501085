import React from "react";

export default function About() {
  return (
    <div className="bg-gradient-to-r from-blue-200 via-blue-400 to-blue-600  ">
      <div className=" text-center ">
        {/* heading responsive */}
        <h1 className="text-4xl font-semibold leading-8 text-black p-6">
          TuDu No.1
        </h1>
        <h1 className="text-4xl font-semibold leading-8 text-black">
          Event Management Platform
        </h1>
        <div className="mt-4 mx-auto flex flex-col md:flex-row items-center md:items-start">
          {/* First div (Our Mission) */}
          <div className="mb-8 md:w-1/2 md:pr-4 m-6 p-6">
            <h3 className="text-2xl font-semibold leading-6 text-black">
              Our Mission
            </h3>
            <p className="mt-2 text-base leading-6  p-6 text-black font-medium text-justify ">
              TuDu is dedicated to simplifying and enhancing the event planning
              experience for individuals and organizations globally. Our mission
              is to provide a user-friendly platform where users can
              effortlessly book, create, or register any type of event. We
              strive to eliminate the complexities associated with event
              planning by offering a comprehensive solution that connects users
              with the perfect venues, services, and resources, ensuring every
              event is a memorable success.
            </p>
          </div>

          {/* Second div (Our Vision) */}
          <div className="mb-8 md:w-1/2 md:pr-4 m-6 p-6 ">
            <h3 className="text-2xl font-semibold leading-6 text-black">
              Our Vision
            </h3>
            <p className="mt-2 text-base leading-6 text-black p-6 font-medium text-justify ">
              At TuDu, we envision a world where event planning is a seamless
              and enjoyable process. Our vision is to become the leading global
              platform that transforms the way people organize and experience
              events. We aim to empower users by providing them with a wide
              range of choices, global connectivity, and intelligent
              recommendations. TuDu seeks to redefine the event planning
              landscape, making it accessible, efficient, and tailored to the
              unique needs and preferences of each user.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
