import React from 'react'
import Navbar from  '../features/navbar/Navbar';
import Gallerycontent from '../features/gallery/Gallerycontent';
import Footer from '../features/footer/Footer';
export default function Gallery() {
  return (
    <div>
    <Navbar/>
    <Gallerycontent/>
    <Footer/>
    </div>
  )
}
