import { colors } from "@mui/material";
import React from "react";

export default function Whytudu() {
  const headingStyle = {
    fontFamily: "'Londrina Shadow', 'Poppins', 'Roboto', sans-serif",
    fontWeight: "bold",
    textAlign: "center",
  };

  return (
    <div>
      <style>
        {`
          @import url('https://fonts.googleapis.com/css2?family=Londrina+Shadow&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto&display=swap');
        `}
      </style>
      <div className="bg-gradient-to-r from-blue-500 via-blue-300 to-blue-500 lg:py-6 md:py-6 sm:py-0">
        <div className="flex flex-col w-full lg:flex-row">
          {/* heading */}
          <div className="grid flex-grow h-20 card  rounded-box place-items-center mt-8 ">
            <article class="text-wrap ">
              <h1
                className="text-balance font-bold text-2xl md:text-5xl lg:text-7xl pl-0 lg:pl-20 "
                style={{ color: "white" }}
              >
                Why TuDu
              </h1>
            </article>
            {/* count of user download and event  */}
            <div className="  lg:pl-20 hidden lg:flex mt-10">
              <div className="stats shadow  ">
                <div className="stat place-items-center text-black   bg-white hover:bg-blue-200">
                  <img
                    src={`${process.env.PUBLIC_URL}/image/download.svg`}
                    alt="Icon"
                    className="h-10 w-10 mb-2 text-blue-900"
                  />
                  <div className="stat-title text-black font-semibold">
                    Downloads
                  </div>
                  <div className="stat-value text-blue-900">31K</div>
                </div>
                <div className="stat place-items-center text-black bg-white hover:bg-blue-200">
                  <img
                    src={`${process.env.PUBLIC_URL}/image/user.svg`}
                    alt="Icon"
                    className="h-10 w-10 mb-2 text-blue-900"
                  />
                  <div className="stat-title text-black font-semibold">
                    Users
                  </div>
                  <div className="stat-value text-blue-900  ">4,200</div>
                </div>
                <div className="stat place-items-center text-black bg-white hover:bg-blue-200">
                  <img
                    src={`${process.env.PUBLIC_URL}/image/event.svg`}
                    alt="Icon"
                    className="h-10 w-10 mb-2 text-blue-900"
                  />
                  <div className="stat-title text-black font-semibold">
                    Registed Event
                  </div>
                  <div className="stat-value text-blue-900">1,200</div>
                </div>
              </div>
            </div>
          </div>
          {/*8 advantages  */}
          <div className="grid flex-grow h-full card  rounded-box place-items-center  pb-4 pr-4 lg:pr-32 ">
            <ul className="timeline timeline-vertical ">
              {/*  */}
              <li className="hover:scale-110">
                <hr className="bg-black" />
                <div className="timeline-start text-black  font-semibold  ">
                  Friendly Team
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 text-black  "
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end timeline-box ml-2 text-black bg-white">
                  More than 200 Team
                </div>
                <hr className="bg-black" />
              </li>
              {/*  */}
              <li className="hover:scale-110">
                <hr className="bg-black" />
                <div className="timeline-start text-black font-semibold">
                  Perfect Venue
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 text-black "
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end timeline-box  ml-2 text-black bg-white">
                  Best Event Destination
                </div>
                <hr className="bg-black" />
              </li>
              {/*  */}
              <li className="hover:scale-110">
                <hr className="bg-black" />
                <div className="timeline-start text-black font-semibold">
                  24/7 Hrs Active
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5  text-black "
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end timeline-box  ml-2 text-black bg-white">
                  Anytime, Anywhere, Always Active
                </div>
                <hr className="bg-black" />
              </li>
              {/*  */}
              <li className="hover:scale-110">
                <hr className="bg-black" />
                <div className="timeline-start text-black font-semibold">
                  Brilliant idea
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5  text-black "
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end timeline-box  ml-2 text-black bg-white">
                  Magical Thinking
                </div>
                <hr className="bg-black" />
              </li>
              {/*  */}
              <li className="hover:scale-110">
                <hr className="bg-black" />
                <div className="timeline-start text-black font-semibold">
                  Best Management
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5  text-black "
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end timeline-box  ml-2 text-black bg-white">
                  Strategic Mastery
                </div>
                <hr className="bg-black" />
              </li>
              {/*  */}
              <li className="hover:scale-110">
                <hr className="bg-black" />
                <div className="timeline-start text-black font-semibold">
                  Get Stuff Done
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 text-black  "
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end timeline-box  ml-2 text-black bg-white ">
                  Execute, Complete, Done
                </div>
                <hr className="bg-black" />
              </li>
              {/*  */}
              <li className="hover:scale-110">
                <hr className="bg-black" />
                <div className="timeline-start text-black font-semibold">
                  Best Host
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5  text-black "
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end timeline-box  ml-2 text-black bg-white">
                  Host with Confidence
                </div>
                <hr className="bg-black" />
              </li>
              {/*  */}
              <li className="hover:scale-110 ">
                <hr className="bg-black" />
                <div className="timeline-start text-black font-semibold">
                  Timeless Memory
                </div>
                <div className="timeline-middle">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5  text-black "
                  >
                    <path
                      fillRule="evenodd"
                      d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.857-9.809a.75.75 0 00-1.214-.882l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <div className="timeline-end timeline-box  ml-2 text-black bg-white">
                  Moments into Treasures
                </div>
                <hr className="bg-black" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
