import React from 'react'
import Signup from '../features/auth/Signup'


export default function SignupPage() {
  return (
    <div>
      <Signup/>

    </div>
  )
}
