import React from "react";
import { useParams } from "react-router-dom";
import { items } from "./items";
import { Link } from "react-router-dom";
export default function EventDetails() {
  const { id } = useParams();

  const eventDetail = items.find((item) => item.id === id);

  if (!eventDetail) {
    return <div>No event found</div>;
  }

  return (
    <div
      style={{
        background: "linear-gradient(to bottom, #f0f8ff, #90cdf4, #3a7bb0)",
      }}
    >
      <div className="container mx-auto p-4 flex justify-center items-center  ">
        <div className="   ">
          <div
            key={`item-${eventDetail.id}`}
            className="max-w-full p-4 border border-black"
          >
            {/* hidden Category*/}
            <p className="hidden">{eventDetail.category}</p>
            <p className="hidden">{eventDetail.id}</p>

            {/* Image */}
            <div className="flex justify-center items-center">
              <img
                src={eventDetail.image}
                alt={`Event ${eventDetail.id}`}
                className="mb-2 rounded-md object-fill h-48 w-96"
              />
            </div>
            {/* Location, Date, and Time */}
            <div className="mb-4">
              <div className="">
                <h2 className=" font-semibold mb-2 text-black sm:text-2xl md:text-3xl text-xl">
                  {eventDetail.name}
                </h2>
              </div>

              <p className="flex items-center  text-black text-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  className="h-3 w-3 m-0.5 text-black-500 "
                >
                  <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                </svg>
                {eventDetail.venue}
              </p>
              <p className="flex items-center text-black text-lg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  className="h-3 w-3 m-0.5 text-black-500"
                >
                  <path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z" />
                </svg>
                {eventDetail.startDate} - {eventDetail.endDate}
              </p>
            </div>

            {/* Event Details */}
            <p>
              <h2 className="text-xl font-semibold mb-2 text-black">
                Event Information
              </h2>
              <h6 className="text-xl font-base mb-2 text-black ">
                {eventDetail.fulltitle}
              </h6>
              <h6 className="ml-4  mb-2 text-black max-w-md ">
                {eventDetail.description}
              </h6>
            </p>

            {/* important links */}
            {eventDetail.links && eventDetail.links.length > 0 && (
              <div>
                <h2 className="text-xl font-semibold mb-2 text-black">
                  Important Links
                </h2>
                <ul>
                  {eventDetail.links.map((link, index) => (
                    <li key={index} className="px-2 text-black">
                      <a
                        className="font-base text-black underline underline-offset-4 py-1 hover:text-red-900"
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {link.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* book now */}
            <div className="flex justify-center mt-2">
              {eventDetail.tickets && eventDetail.tickets.length > 0 ? (
                <Link
                  to={`/price/${eventDetail.id}`}
                  className="bg-blue-800 text-white hover:bg-blue-900 px-2 py-2 rounded-md text-lg font-semibold m-1"
                >
                  Book Now
                </Link>
              ) : (
                <Link
                  to=""
                  className="bg-blue-800 text-white hover:bg-blue-900 px-2 py-2 rounded-md text-lg font-semibold m-1"
                >
                  Book Now
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}